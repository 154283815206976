import GeniusImagesList from "./GeniusImages";

export default function AutoScrollingGeniusImages() {
  return (
    <div className="inline-flex w-full overflow-hidden">
      <GeniusImagesList />

      <GeniusImagesList ariaHidden="true" />
    </div>
  );
}