import { redirect } from "react-router-dom";

export default async function navigateAction({
  request,
}: {
  request: Request;
}) {
  const formData = await request.formData();

  console.log("formData", Object.fromEntries(formData));

  return redirect("/onboarding/youtube-or-podcasts");
}
