import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";

import Typewriter from "../../components/Typewriter";
import magnet from "@assets/magnet.png";
import ScrollLayout from "../../components/ScollLayout";

export default function ReplaceScrollingPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    navigate("/onboarding/interests");
  };

  return (
    <ScrollLayout>
      <div className="flex h-full flex-col text-center custom-container">
        <div className="flex grow flex-col items-center justify-center gap-8 px-8">
          <img src={magnet} alt="Magnet" className="size-40 object-scale-down	" />

          <Typewriter text={t("pages.onboarding.scrolling.title")} />
        </div>

        <div className="custom-container shrink-0 py-8 max-w-sm">
          <Button onClick={handleClick} title={t("shared.continue")} />
        </div>
      </div>
    </ScrollLayout>
  );
}
