import * as Sentry from "@sentry/react";
import { AuthError, createClient, Session, User } from "@supabase/supabase-js";
import { EdgeFunctionError, StripeCoupon, StripePrice } from "src/types";

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL as string;
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY as string;

const supabase = createClient(supabaseUrl, supabaseKey);

export async function createStripeSubscription({
  couponId,
  currency,
  email,
  priceId,
}: {
  couponId: string;
  currency: string;
  email: string | undefined;
  priceId: string;
}) {
  const response = await supabase.functions.invoke(
    "create-stripe-subscription",
    {
      body: {
        ...(couponId && { couponId }),
        currency,
        email,
        priceId,
      },
    },
  );

  const data = response.data as {
    clientSecret: string;
    subscriptionId: string;
  };

  const error = response.error as EdgeFunctionError;

  if (error) throw error;

  return { data };
}

export async function getUser(): Promise<{
  data: { user: User | null };
  error: AuthError | null;
}> {
  const { data, error } = await supabase.auth.getUser();

  return { data, error };
}

export async function getSession(): Promise<{
  data: { session: Session | null };
  error: AuthError | null;
}> {
  const { data, error } = await supabase.auth.getSession();

  return { data, error };
}

export async function listStripeCoupons(): Promise<StripeCoupon[]> {
  const response = await supabase.functions.invoke("list-stripe-coupons");

  const data = response.data as StripeCoupon[];
  const error = response.error as EdgeFunctionError;

  if (error) throw error;

  return data;
}

export async function listStripePrices(): Promise<StripePrice[]> {
  const response = await supabase.functions.invoke("list-stripe-prices", {
    body: {
      productId: import.meta.env.VITE_STRIPE_PRODUCT_ID as string,
    },
  });

  const data = response.data as StripePrice[];
  const error = response.error as EdgeFunctionError;

  if (error) throw error;

  return data;
}

export async function resetPasswordForEmail({ email }: { email: string }) {
  const { error } = await supabase.auth.resetPasswordForEmail(email, {
    redirectTo: `${window.location.origin}/account/reset-password`,
  });

  if (error) {
    console.error("error", error);
  }

  return { error };
}

export async function signInWithPassword({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });

  if (error) Sentry.captureException(error);

  return { data, error };
}

export async function signUp({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  const { error } = await supabase.auth.signUp({
    email,
    password,
  });

  if (error) {
    console.error("error", error);
  }

  return { error };
}

export async function updatePassword({ password }: { password: string }) {
  const { error } = await supabase.auth.updateUser({
    password,
  });

  if (error) {
    console.error("error", error);
  }

  return { error };
}
