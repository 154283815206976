import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Typewriter from "../../components/Typewriter";
import einstein from "@assets/icon.svg";
import { Confetti } from "../../components/Confetti";
import { useState } from "react";
import ScrollLayout from "../../components/ScollLayout";

export default function WelcomePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [checked, setChecked] = useState<boolean>(true);

  const handleClick = () => {
    navigate("/onboarding2/dive");
  };

  return (
    <ScrollLayout>
      {checked && (
        <Confetti
          onFinish={() => {
            return;
          }}
        />
      )}
      <div className="flex h-full flex-col text-center custom-container">
        <div className="flex grow flex-col items-center justify-center gap-0">
          <img
            src={einstein}
            alt="Albert Einstein"
            className="size-40 object-scale-down	"
          />

          <Typewriter
            text={t("pages.onboarding.welcome.title")}
            onFinish={() => setChecked(false)}
          />
        </div>

        <div className="custom-container py-8 shrink-0 max-w-sm">
          <Button onClick={handleClick} title={t("shared.continue")} />
        </div>
      </div>
    </ScrollLayout>
  );
}
