export function trackCompleteRegistration() {
  if (fbq) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    fbq("track", "CompleteRegistration");
  }
}

export function trackInitiateCheckout({
  amountInCents,
  currency,
}: {
  amountInCents: number;
  currency: string;
}) {
  if (fbq) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    fbq("track", "InitiateCheckout", {
      currency: currency.toUpperCase(),
      value: amountInCents / 100,
    });
  }
}

export async function trackPurchase({
  amountInCents,
  currency,
}: {
  amountInCents: number;
  currency: string;
}) {
  return new Promise<void>((resolve, reject) => {
    if (fbq) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      fbq("track", "Purchase", {
        currency: currency.toUpperCase(),
        value: amountInCents / 100,
      });

      resolve();
    } else {
      reject(new Error("Facebook Pixel not loaded"));
    }
  });
}
