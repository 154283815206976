import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import { Navigate, useSearchParams } from "react-router-dom";
import CheckoutForm from "../../components/CheckoutForm";
import ScrollLayout from "../../components/ScollLayout";

const stripePromise = loadStripe(
  import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY as string,
);

export default function PaymentPage() {
  const [searchParams] = useSearchParams();

  const clientSecret = searchParams.get("client_secret")!;
  const subscriptionId = searchParams.get("subscription_id")!;

  const { t } = useTranslation();

  if (!clientSecret) return <Navigate replace to="/checkout/plans" />;

  return (
    <ScrollLayout>
      <div className="flex grow flex-col font-sentient custom-container">
        <div className="shrink-0 space-y-2">
          <p className="font-bold ">
            {t("pages.checkout.payment.securePaymentTitle")}
          </p>

          <p>{t("pages.checkout.payment.securePaymentText")}</p>

          <p className="font-bold">
            {t("pages.checkout.payment.moneyBackGuaranteeTitle")}
          </p>

          <p>{t("pages.checkout.payment.moneyBackGuaranteeText")}</p>
        </div>

        <div className="mt-10 grow">
          <Elements
            options={{
              appearance: { theme: "flat" },
              clientSecret,
            }}
            stripe={stripePromise}
          >
            <CheckoutForm
              clientSecret={clientSecret}
              subscriptionId={subscriptionId}
            />
          </Elements>
        </div>
      </div>
    </ScrollLayout>
  );
}
