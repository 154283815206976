import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { PaymentIntent } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { trackInitiateCheckout } from "../helpers/facebookTracking";
import localizeCurrency from "../helpers/localizeCurrency";
import Button from "./Button";

interface Props {
  clientSecret: string;
  subscriptionId: string;
}

export default function CheckoutForm({ clientSecret, subscriptionId }: Props) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | undefined>(
    undefined,
  );

  const elements = useElements();
  const stripe = useStripe();
  const { t } = useTranslation();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!elements || !stripe) return;

    setIsSubmitting(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/checkout/payment-completion?subscription_id=${subscriptionId}`,
      },
    });

    setIsSubmitting(false);

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      throw error;
    }
  };

  useEffect(() => {
    if (!clientSecret || !stripe) {
      return;
    }

    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        setPaymentIntent(paymentIntent);

        if (paymentIntent?.last_payment_error) {
          if (
            paymentIntent.last_payment_error.code ===
            "payment_intent_authentication_failure"
          ) {
            setMessage(t("components.checkoutForm.paymentFailed"));
          } else {
            setMessage(t("components.checkoutForm.somethingWentWrong"));
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  }, [clientSecret, setPaymentIntent, stripe, t]);

  useEffect(() => {
    if (!paymentIntent) return;

    trackInitiateCheckout({
      amountInCents: paymentIntent.amount,
      currency: paymentIntent.currency,
    });
  }, [paymentIntent]);

  useEffect(() => {
    const resetSubmittingState = () => {
      setIsSubmitting(false);
    };

    window.addEventListener("pageshow", resetSubmittingState);
    document.addEventListener("visibilitychange", resetSubmittingState);

    return () => {
      window.removeEventListener("pageshow", resetSubmittingState);
      document.removeEventListener("visibilitychange", resetSubmittingState);
    };
  }, []);

  return (
    <form
      className="flex h-full flex-col justify-between space-y-4"
      onSubmit={handleSubmit}
    >
      <div className="rounded-3xl bg-white p-5">
        <PaymentElement
          options={{
            paymentMethodOrder: ["card", "paypal", "apple_pay", "google_pay"],
          }}
        />
      </div>

      {message && (
        <p className="text-center text-lg font-bold text-custom-error">
          {message}
        </p>
      )}

      <div>
        {/* TODO: Fetch original plan price and display it here */}
        {/* <p className={`font-bold ${discounted && "line-through"}`}>
          {t("pages.checkout.payment.thisYear", {
            price: localizeCurrency({
              amountInCents: planAmount,
              currency: planCurrency,
            }),
          })}
        </p> */}

        {paymentIntent && (
          <p className="font-bold">
            {t("pages.checkout.payment.firstYearSpecialOffer", {
              price: localizeCurrency({
                amountInCents: paymentIntent.amount,
                currency: paymentIntent.currency,
              }),
            })}
          </p>
        )}

        <Button
          className="mt-7"
          disabled={isSubmitting || !elements || !stripe}
          title={t("pages.checkout.payment.submitPayment")}
          type="submit"
        />
      </div>
    </form>
  );
}
