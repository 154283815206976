import { useTranslation } from "react-i18next";
import { Plan, StripeCoupon, StripePrice } from "src/types";
import localizeCurrency from "../helpers/localizeCurrency";
import SubscriptionForm from "./SubscriptionForm";

interface Props {
  bestOffer?: boolean;
  currency: string;
  isSubmitting: boolean;
  periodAmount: number;
  periodUnit: string;
  plan: Plan;
  selected: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setSelectedPlan: (plan: Plan) => void;
  stripeIntroPriceCoupon: StripeCoupon | undefined;
  stripePrice: StripePrice | undefined;
}

export default function PlanCard({
  bestOffer,
  currency,
  isSubmitting,
  periodAmount,
  periodUnit,
  plan,
  selected,
  setIsSubmitting,
  setSelectedPlan,
  stripeIntroPriceCoupon,
  stripePrice,
}: Props) {
  const { t } = useTranslation();

  if (!stripePrice) return null;

  const hasCurrencyOption =
     !!stripePrice.currency_options?.[currency]?.unit_amount_decimal;

   const price = parseFloat(
     hasCurrencyOption
       ? stripePrice.currency_options[currency].unit_amount_decimal
       : stripePrice?.unit_amount_decimal,
   );

   const priceCurrency = hasCurrencyOption ? currency : stripePrice.currency;

  if (!price) return null;

  const pricePerPeriod = price / periodAmount;

  const percentOff = stripeIntroPriceCoupon?.percent_off;
  const introPrice = percentOff && price * (1 - percentOff / 100);
  const introPricePerPeriod = introPrice && introPrice / periodAmount;

  const localizePrice = (price: number | undefined) => {
    if (!price) return "";

    return localizeCurrency({
      amountInCents: price,
      currency: priceCurrency,
    });
  };

  const localizeBilledAt = () => {
    let translationKey = "pages.checkout.plans.";

    switch (plan as string) {
      case "weekly":
        translationKey += "billedWeeklyAt";
        break;
      case "yearly":
        translationKey += "billedAnnuallyAt";
        break;
      default:
        throw new Error(`Unhandled plan: ${plan}`);
    }

    return t(translationKey, { price: localizePrice(price) });
  };

  const localizeSpecialOffer = () => {
    if (!percentOff) return localizeBilledAt();

    let translationKey = "pages.checkout.plans.";

    switch (plan as string) {
      case "weekly":
        translationKey += "firstWeekSpecialOffer";
        break;
      case "yearly":
        translationKey += "firstYearSpecialOffer";
        break;
      default:
        throw new Error(`Unhandled plan: ${plan}`);
    }

    return t(translationKey, {
      price: localizePrice(percentOff ? introPrice : price),
    });
  };

  return (
    <div className="">
      {bestOffer && (
        <div className="-mb-8 rounded-t-3xl border border-black p-5 pb-12 text-center font-oswald">
          <p className="text-2xl font-bold uppercase">
            {t("pages.checkout.plans.bestOffer")}
          </p>
        </div>
      )}

      <div
        className={`z-10 rounded-3xl bg-white border-black ${selected ? "border-3" : "border-2"} px-6 pb-12 pt-5 text-center`}
        onClick={() => setSelectedPlan(plan)}
      >
        <p className="text-4xl font-bold font-oswald">{periodAmount}</p>

        <p className="mt-3 text-lg uppercase font-oswald">
          {t(`pages.checkout.plans.${periodUnit}`, { count: periodAmount })}
        </p>

        {percentOff && (
          <p className="mt-3 text-2xl line-through font-sentient">
            {localizePrice(pricePerPeriod)}
          </p>
        )}

        <p className="mt-2 text-2xl font-bold  font-sentient">
          {localizePrice(percentOff ? introPricePerPeriod : pricePerPeriod)} /{" "}
          {t(`pages.checkout.plans.${periodUnit}`, { count: 1 })}
        </p>

        {percentOff && (
          <p className="mt-4 text-[#BBBFCB] line-through font-sentient">
            {localizeBilledAt()}
          </p>
        )}

        <p className="font-semibold  font-sentient text-[#BBBFCB]">{localizeSpecialOffer()}</p>

        <SubscriptionForm
          buttonClassName="mt-5 uppercase"
          buttonTitle={t("pages.checkout.plans.subscribeNow")}
          couponId={stripeIntroPriceCoupon?.id}
          currency={priceCurrency}
          isSubmitting={isSubmitting}
          priceId={stripePrice.id}
          setIsSubmitting={setIsSubmitting}
        />

        <p className="mt-7  font-sentient">{t("pages.checkout.plans.cancelAnytime")}</p>

        <p className="mt-3 font-bold  font-sentient">
          {t("pages.checkout.plans.moneyBackGuarantee")}
        </p>
      </div>
    </div>
  );
}
