import * as Sentry from "@sentry/react";
import React from "react";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import FacebookPixel from "./components/FacebookPixel";
import "./i18n";
import "./index.css";
import ErrorPage from "./pages/ErrorPage";
import routes from "./pages/routes";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN as string,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      createRoutesFromChildren,
      matchRoutes,
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
    }),
  ],
  tracesSampleRate: 1.0,
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(routes);

export default function App() {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <FacebookPixel
        pixelId={import.meta.env.VITE_FACEBOOK_PIXEL_ID as string}
      />

      <RouterProvider router={router} />
    </Sentry.ErrorBoundary>
  );
}
