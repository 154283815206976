import { Navigate } from "react-router-dom";
import signInAction from "../../actions/signInAction";
import getSubscriptionLoader from "../../loaders/getSubscriptionLoader";
import ForgotPasswordPage from "./ForgotPasswordPage";
import ResetPasswordPage from "./ResetPasswordPage";
import SignInPage from "./SignInPage";
import SubscriptionPage from "./SubscriptionPage";

const accountRoutes = [
  {
    index: true,
    element: <Navigate to="subscription" replace />,
  },
  {
    element: <ForgotPasswordPage />,
    path: "forgot-password",
  },
  {
    element: <ResetPasswordPage />,
    path: "reset-password",
  },
  {
    action: signInAction,
    element: <SignInPage />,
    path: "sign-in",
  },
  {
    element: <SubscriptionPage />,
    loader: getSubscriptionLoader,
    path: "subscription",
  },
];

export default accountRoutes;
