import { redirect } from "react-router-dom";
import { createStripeSubscription, getUser } from "../helpers/supabase";

export default async function createStripeSubscriptionAction({
  request,
}: {
  request: Request;
}) {
  const { couponId, currency, priceId } = await getFormValues(request);

  if (!currency || !priceId) return redirect("/checkout/plans");

  const { data: userData } = await getUser();

  const email = userData?.user?.email;

  if (!email) return redirect("/checkout/plans");

  const { data } = await createStripeSubscription({
    couponId,
    currency,
    email,
    priceId,
  });

  const { clientSecret, subscriptionId } = data as {
    clientSecret: string;
    subscriptionId: string;
  };

  return redirect(
    `/checkout/payment?client_secret=${clientSecret}&subscription_id=${subscriptionId}`,
  );
}

async function getFormValues(request: Request): Promise<{
  couponId: string;
  currency: string;
  priceId: string;
}> {
  const formData = await request.formData();

  const { couponId, currency, priceId } = Object.fromEntries(formData);

  return { couponId, currency, priceId } as {
    couponId: string;
    currency: string;
    priceId: string;
  };
}
