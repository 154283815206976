import { Navigate, Outlet } from "react-router-dom";
import createStripeSubscriptionAction from "../../actions/createStripeSubscriptionAction";
import completePaymentLoader from "../../loaders/completePaymentLoader";
import getPricesLoader from "../../loaders/getPricesLoader";
import getUserLoader from "../../loaders/getUserLoader";
import PaymentCompletionPage from "./PaymentCompletionPage";
import PaymentPage from "./PaymentPage";
import PlansPage from "./PlansPage";

const checkoutRoutes = [
  {
    element: <Outlet />,
    loader: getUserLoader,
    path: "/checkout",
    children: [
      {
        index: true,
        element: <Navigate to="plans" replace />,
      },
      {
        element: <PaymentPage />,
        path: "payment",
      },
      {
        element: <PaymentCompletionPage />,
        loader: completePaymentLoader,
        path: "payment-completion",
      },
      {
        action: createStripeSubscriptionAction,
        element: <PlansPage />,
        loader: getPricesLoader,
        path: "plans",
      },
    ],
  },
];

export default checkoutRoutes;
