import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import Dialog from "./Dialog";

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function AlreadySignedUpDialog({ open, onClose }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    navigate("/account/sign-in");
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <div className="space-y-14">
        <p className="text-2xl font-bold font-oswald text-center text-black">
          {t("pages.signUp.email.alreadySignedUpDialog.text")}
        </p>

        <Button
          onClick={handleClick}
          title={t("pages.signUp.email.alreadySignedUpDialog.logIn")}
        />
      </div>
    </Dialog>
  );
}
