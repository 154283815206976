interface Geolocation {
  currency: string;
}

export default async function getCurrencyFromGeolocation(): Promise<string> {
  const response = await fetch("https://api.asanarebel.com/api/v1/geolocation");

  console.log(response);

  const geolocation = (await response.json()) as Geolocation;

  return geolocation?.currency?.toLowerCase() || "eur";
}
