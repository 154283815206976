import { Navigate } from "react-router-dom";
import isUserSignedInLoader from "../../loaders/isUserSignedInLoader";
import EmailPage from "./EmailPage";
import PasswordPage from "./PasswordPage";

const signUpRoutes = [
  {
    index: true,
    element: <Navigate to="password" replace />,
  },
  {
    element: <EmailPage />,
    path: "email",
  },
  {
    element: <PasswordPage />,
    loader: isUserSignedInLoader,
    path: "password",
  },
];

export default signUpRoutes;
