import i18n from "../i18n";

export default function localizeCurrency({
  amountInCents,
  currency,
}: {
  amountInCents: number;
  currency: string;
}): string {
  const amount = Math.floor(amountInCents) / 100;

  return new Intl.NumberFormat(i18n.language, {
    currency: currency,
    style: "currency",
  }).format(amount);
}
