import { Plan, StripeCoupon, StripePrice } from "src/types";
import PlanCard from "./PlanCard";

interface Props {
  currency: string;
  introPriceCoupon: StripeCoupon | undefined;
  isSubmitting: boolean;
  selectedPlan: Plan;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setSelectedPlan: (plan: Plan) => void;
  weeklyPrice: StripePrice | undefined;
  yearlyPrice: StripePrice | undefined;
}

export default function Plans({
  currency,
  introPriceCoupon,
  isSubmitting,
  selectedPlan,
  setIsSubmitting,
  setSelectedPlan,
  weeklyPrice,
  yearlyPrice,
}: Props) {
  return (
    <div className="grid grid-cols-1 items-end gap-8 md:grid-cols-2">
      <PlanCard
        bestOffer
        currency={currency}
        isSubmitting={isSubmitting}
        periodAmount={12}
        periodUnit="month"
        plan="yearly"
        selected={selectedPlan === "yearly"}
        setIsSubmitting={setIsSubmitting}
        setSelectedPlan={setSelectedPlan}
        stripeIntroPriceCoupon={introPriceCoupon}
        stripePrice={yearlyPrice}
      />

      <PlanCard
        currency={currency}
        isSubmitting={isSubmitting}
        periodAmount={1}
        periodUnit="week"
        plan="weekly"
        selected={selectedPlan === "weekly"}
        setIsSubmitting={setIsSubmitting}
        setSelectedPlan={setSelectedPlan}
        stripeIntroPriceCoupon={introPriceCoupon}
        stripePrice={weeklyPrice}
      />
    </div>
  );
}
