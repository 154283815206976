import * as Sentry from "@sentry/react";

export default async function createRevenueCatPurchase({
  subscriptionId,
  userId,
}: {
  subscriptionId: string;
  userId: string;
}) {
  const response = await fetch(`https://api.revenuecat.com/v1/receipts`, {
    headers: {
      Authorization: `Bearer ${import.meta.env.VITE_REVENUECAT_API_KEY}`,
      "Content-Type": "application/json",
      "X-Platform": "stripe",
    },
    body: JSON.stringify({
      app_user_id: userId,
      fetch_token: subscriptionId,
    }),
    method: "POST",
  });

  if (!response.ok) {
    Sentry.captureException(
      `Creating RevenueCat purchase failed with app_user_id=${userId} and fetch_token=${subscriptionId}`,
    );

    return null;
  } else {
    return response.json();
  }
}
