import { redirect } from "react-router-dom";
import { getUser } from "../helpers/supabase";

export default async function getUserLoader() {
  const { data } = await getUser();

  if (!data?.user) {
    let url = "/account/sign-in";

    if (location.pathname) {
      url += `?path=${location.pathname}`;
    }

    return redirect(url);
  }

  return data.user;
}
