import { Navigate, Outlet } from "react-router-dom";
import skipOnboardingLoader from "../../loaders/skipOnboardingLoader";
import TopicsPage from "./TopicsPage";
import WelcomePage from "./WelcomePage";
import DivePage from "./DivePage";
import AreaPage from "./AreaPage";
import ConceptsPage from "./ConceptsPage";
import InspirationPage from "./InspirationPage";
import BigIdeasPage from "./BigIdeasPage";
import LearningPage from "./LearningPage";
import InterestTopic1Page from "./InterestTopic1Page";
import InterestTopic2Page from "./InterestTopic2Page";
import InterestTopic3Page from "./InterestTopic3Page";
import InterestTopic4Page from "./InterestTopic4Page";
import LearningGoalPage from "./LearningGoalPage";

const onboardingRoutes2 = [
  {
    path: "/onboarding2",
    element: <Outlet />,
    loader: skipOnboardingLoader,
    children: [
      {
        index: true,
        element: <Navigate to="topics" replace />,
      },
      {
        path: "topics",
        element: <TopicsPage />,
      },
      {
        path: "welcome",
        element: <WelcomePage />,
      },
      {
        element: <DivePage />,
        path: "dive",
      },
      {
        element: <AreaPage />,
        path: "area",
      },
      {
        element: <ConceptsPage />,
        path: "concepts",
      },
      {
        element: <BigIdeasPage />,
        path: "ideas",
      },
      {
        element: <InspirationPage />,
        path: "inspiration",
      },
      {
        element: <LearningPage />,
        path: "learning",
      },
      {
        element: <InterestTopic1Page />,
        path: "topic1",
      },
      {
        element: <InterestTopic2Page />,
        path: "topic2",
      },
      {
        element: <InterestTopic3Page />,
        path: "topic3",
      },
      {
        element: <InterestTopic4Page />,
        path: "topic4",
      },
      {
        element: <LearningGoalPage />,
        path: "goal",
      },
    ],
  },
];

export default onboardingRoutes2;
