const PASSWORD_KEY = "password";

export function getPasswordFromSessionStorage(): string | null {
  return window.sessionStorage.getItem(PASSWORD_KEY);
}

export function removePasswordFromSessionStorage(): void {
  window.sessionStorage.removeItem(PASSWORD_KEY);
}

export function setPasswordInSessionStorage(password: string): void {
  window.sessionStorage.setItem(PASSWORD_KEY, password);
}
