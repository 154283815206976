import { Suspense } from "react";
import {
  Await,
  Navigate,
  useLoaderData,
  useSearchParams,
} from "react-router-dom";
import ErrorPage from "../ErrorPage";
import LoadingPage from "../LoadingPage";

export default function PaymentCompletionPage() {
  const data = useLoaderData() as { completed: Promise<boolean> };

  const [searchParams] = useSearchParams();

  const paymentIntentClientSecret = searchParams.get(
    "payment_intent_client_secret",
  )!;

  const subscriptionId = searchParams.get("subscription_id")!;

  return (
    <Suspense fallback={<LoadingPage />}>
      <Await errorElement={<ErrorPage />} resolve={data.completed}>
        {(completed) =>
          completed ? (
            <Navigate to="/account/subscription" />
          ) : (
            <Navigate
              to={`/checkout/payment?client_secret=${paymentIntentClientSecret}&subscription_id=${subscriptionId}`}
            />
          )
        }
      </Await>
    </Suspense>
  );
}
