import { CheckCircleIcon } from "@heroicons/react/24/solid";

interface Props {
  text: string;
  title: string;
}

export default function BulletPoint({ text, title }: Props) {
  return (
    <div className="flex items-start gap-4">
      <CheckCircleIcon className="h-8 w-8 shrink-0 text-black" />

      <div>
        <p className="font-bold font-sentient">{title}</p>

        <p className="font-sentient">{text}</p>
      </div>
    </div>
  );
}
