import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import austen from "@assets/austen.png";
import einstein from "@assets/einstein.png";
import gogh from "@assets/gogh.png";
import kafka from "@assets/kafka.png";
import curie from "@assets/curie.png";
import galilei from "@assets/galilei.png";
import darwin from "@assets/darwin.png";
import newton from "@assets/newton.png";
import tesla from "@assets/tesla.png";
import dickens from "@assets/dickens.png";
import twain from "@assets/twain.png";
import homer from "@assets/homer.png";
import smith from "@assets/smith.png";
import keynes from "@assets/keynes.png";
import friedman from "@assets/friedman.png";
import hayek from "@assets/hayek.png";
import shakespeare from "@assets/shakespeare.png";
import nietzsche from "@assets/nietzsche.png";
import aurelius from "@assets/aurelius.png";
import aristotle from "@assets/aristotle.png";
import socrates from "@assets/socrates.png";
import popper from "@assets/popper.png";
import wittgenstein from "@assets/wittgenstein.png";
import davinci from "@assets/davinci.png";
import picasso from "@assets/picasso.png";
import michelangelo from "@assets/michelangelo.png";

import Button from "../../components/Button";
import useLocalStorage from "react-use/lib/useLocalStorage";
import ScrollLayout from "../../components/ScollLayout";

export default function Inspiration() {
  const [selected, setSelected] = useState<number | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [value, ,] = useLocalStorage<string | null>("selectedTopic", null);

  const handleClick = () => {
    navigate("/onboarding2/learning");
  };

  const handleSelect = (selected: number) => {
    setTimeout(() => {
      setSelected(selected);
    }, 500);
  };

  const images = [
    [galilei, einstein, darwin, tesla, curie, newton], // science
    [austen, shakespeare, kafka, dickens, twain, homer], // literature
    [smith, keynes, friedman, hayek], // eco
    [nietzsche, aurelius, aristotle, socrates, popper, wittgenstein], // philo
    [davinci, picasso, michelangelo, gogh], // art
  ];

  function getIndex() {
    switch (value) {
      case "science":
        return 0;
      case "literature":
        return 1;
      case "economics":
        return 2;
      case "philosophy":
        return 3;
      case "art":
        return 4;
      default:
        return 0;
    }
  }

  function getGenius() {
    if (selected === null) return "";
    return t(`pages.onboarding.${value}Area.person${selected + 1}`);
  }

  function getTitle() {
    if (selected === null) return "";
    return t(`pages.onboarding.${value}Area.person${selected + 1}Quote`);
  }

  const InitialContent = () => {
    return (
      <main className="flex h-full min-h-dvh flex-col text-black">
        <div className="custom-container top-0 shrink-0 bg-white py-8">
          <h1 className="heading">
            {t("pages.onboarding.inspiration.heading")}
          </h1>
        </div>

        <div className="custom-container flex grow flex-col justify-center py-8">
          <div className="grid grid-cols-2 gap-5 px-8 pb-36">
            {images[getIndex()].map((image, index) => (
              <button
                key={index}
                className={`flex items-start justify-${index % 2 == 0 ? "end" : "start"}`}
                onClick={() => handleSelect(index)}
              >
                <div className="flex flex-col items-center justify-center gap-2">
                  <img
                    src={image}
                    alt="Einstein"
                    className="h-40 w-40 object-scale-down"
                  />
                  <h1 className="font-oswald text-base">
                    {t(`pages.onboarding.${value}Area.person${index + 1}`)}
                  </h1>
                </div>
              </button>
            ))}
          </div>
        </div>
      </main>
    );
  };

  return (
    <div>
      <ScrollLayout>
        <InitialContent />
      </ScrollLayout>
      {selected !== null && (
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-black/80">
          <div className="flex h-full flex-col text-cente custom-container">
            {Array.from({ length: selected + 1 }, (_, index) => (
              <h1 key={index} className="grow"></h1>
            ))}
            <div className="custom-container grow">
              <h1 className="px-4 py-2 text-left text-white">{getGenius()}</h1>
              <div className=" rounded-2xl bg-blue-500 px-4 py-4">
                <h2 className="text-left text-white">{getTitle()}</h2>
              </div>
            </div>
            <h1 className="grow"></h1>
            <h1 className="grow"></h1>

            <div className="custom-container sticky bottom-14 shrink-0 bg-transparent max-w-sm">
              <Button
                className="bg-white text-black"
                disabled={false}
                onClick={handleClick}
                title={t("shared.continue")}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
