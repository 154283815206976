import {
  DialogPanel,
  DialogTitle,
  Dialog as HeadlessUiDialog,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";

interface Props {
  children: React.ReactNode;
  onClose: () => void;
  open: boolean;
}

export default function Dialog({ children, open, onClose }: Props) {
  return (
    <Transition show={open}>
      <HeadlessUiDialog onClose={onClose} open={open}>
        {/* NOTE: The backdrop, rendered as a fixed sibling to the panel container */}
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div aria-hidden="true" className="fixed inset-0 bg-black/70" />
        </TransitionChild>

        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {/* NOTE: Full-screen container to center the panel */}
          <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
            {/* NOTE: The actual dialog panel  */}
            <DialogPanel className="max-w-lg space-y-4 rounded-3xl bg-white p-8 text-white shadow">
              <DialogTitle as="div" className="text-right">
                <button
                  className="rounded-full text-[#82899C]"
                  onClick={onClose}
                  type="button"
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </DialogTitle>

              {children}
            </DialogPanel>
          </div>
        </TransitionChild>
      </HeadlessUiDialog>
    </Transition>
  );
}
