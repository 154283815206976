import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import TopicButton from "../../components/TopicButton";

export default function TopicsPage() {
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    navigate("/onboarding/person");
  };

  return (
    <main className="flex h-full min-h-dvh flex-col text-black">
      <div className="custom-container max-w-xl flex grow flex-col justify-center py-8">
      <div className="bg-white custom-container top-0 shrink-0 py-8">
        <h1 className="heading">{t("pages.onboarding.topics.heading")}</h1>
        <h2 className="subheading">{t("pages.onboarding.topics.subtitle")}</h2>
      </div>
        <div className="grid grid-cols-2 gap-5 pb-36 px-8">
          <TopicButton
            emoji="📖"
            selectedTopics={selectedTopics}
            setSelectedTopics={setSelectedTopics}
            topic="literature"
          />

          <TopicButton
            emoji="🔭"
            selectedTopics={selectedTopics}
            setSelectedTopics={setSelectedTopics}
            topic="science"
          />

          <TopicButton
            emoji="📈"
            selectedTopics={selectedTopics}
            setSelectedTopics={setSelectedTopics}
            topic="economics"
          />

          <TopicButton
            emoji="🤔"
            selectedTopics={selectedTopics}
            setSelectedTopics={setSelectedTopics}
            topic="philosophy"
          />

          <TopicButton
            emoji="🖼️"
            selectedTopics={selectedTopics}
            setSelectedTopics={setSelectedTopics}
            topic="art"
          />
        </div>
      </div>

      <div className="custom-container w-full sticky bottom-14 shrink-0 bg-transparent max-w-sm">
        <Button
          disabled={selectedTopics.length === 0}
          onClick={handleClick}
          title={t("shared.continue")}
        />
      </div>
    </main>
  );
}
