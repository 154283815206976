import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SingleTopicButton from "../../components/SingleTopicButton";
import useLocalStorage from "react-use/lib/useLocalStorage";
import ScrollLayout from "../../components/ScollLayout";

export default function TopicsPage() {
  const [, setValue,] = useLocalStorage<string | null>("selectedTopic", null);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const setSelectedTopic = (value: string) => {
    setValue(value);
    navigate("/onboarding2/welcome");
  };

  return (
    <ScrollLayout>
      <div className="bg-white custom-container top-0 shrink-0 py-8">
        <h1 className="heading">{t("pages.onboarding.topics.heading2")}</h1>
      </div>

      <div className="custom-container flex grow flex-col justify-center py-8">
        <div className="grid grid-cols-2 gap-5 pb-36">
          <SingleTopicButton
            emoji="📖"
            setSelectedTopic={setSelectedTopic}
            topic="literature"
          />

          <SingleTopicButton
            emoji="🔭"
            setSelectedTopic={setSelectedTopic}
            topic="science"
          />

          <SingleTopicButton
            emoji="📈"
            setSelectedTopic={setSelectedTopic}
            topic="economics"
          />

          <SingleTopicButton
            emoji="🤔"
            setSelectedTopic={setSelectedTopic}
            topic="philosophy"
          />

          <SingleTopicButton
            emoji="🖼️"
            setSelectedTopic={setSelectedTopic}
            topic="art"
          />
        </div>
      </div>
      </ScrollLayout>
  );
}
