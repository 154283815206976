import { useTranslation } from "react-i18next";

interface Props {
  emoji: string;
  selectedTopics: string[];
  setSelectedTopics: (selectedTopics: string[]) => void;
  topic: string;
}

export default function TopicButton({
  emoji,
  selectedTopics,
  setSelectedTopics,
  topic,
}: Props) {
  const { t } = useTranslation();

  const bgColor = selectedTopics.includes(topic)
    ? "black"
    : "transparent";

    const fontColor = selectedTopics.includes(topic)
    ? "white"
    : "black";

  const className = `button-rounded-multiline break-words border-custom-gray bg-${bgColor} text-${fontColor}`;

  const handleClick = (topic: string) => {
    if (selectedTopics.includes(topic)) {
      setSelectedTopics(
        selectedTopics.filter((selectedTopic) => selectedTopic !== topic),
      );
    } else {
      setSelectedTopics([...selectedTopics, topic]);
    }
  };

  return (
    <button className={className} onClick={() => handleClick(topic)}>
      <div className='text-3xl py-2'>{emoji}</div>
      {t(`pages.onboarding.topics.${topic}`)}
    </button>
  );
}
