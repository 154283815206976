import { getOrCreateRevenueCatCustomer } from "./getOrCreateRevenueCatCustomer";

export default async function getSubscriptionStatus(
  userId: string | undefined,
): Promise<"active" | "inactive" | "none"> {
  if (!userId) return "none";

  const revenueCatCustomer = await getOrCreateRevenueCatCustomer(userId);

  const expiresDate: string =
    revenueCatCustomer?.subscriber?.entitlements?.Premium?.expires_date;

  if (!expiresDate) return "none";

  if (expiresDate > new Date().toISOString()) return "active";

  return "inactive";
}
